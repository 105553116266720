@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: normal;
}

.react-colorful__last-control {
  border-radius: 8px !important;
}
